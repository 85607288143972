











































































































































































































import { Component, Vue } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import { UserApi } from "@/networks/UserApi";
import Office from "../../components/inputs/office.vue";
import UserAutoComplete from "@/views/components/inputs/user.vue";
import EventAutoComplete from "@/views/components/inputs/event.vue";
import { EventApi } from "@/networks/EventApi";
import Enum from "@/config/enum";
import FileUploader from "@/views/components/inputs/fileUploader.vue";

@Component({
  components: {
    DatetimePicker,
    office: Office,
    FileUploader,
    UserAuto: UserAutoComplete,
    EventAuto: EventAutoComplete
  }
})
export default class AddEvent extends Vue {
  private types = Enum.eventTypes;
  private times: Array<Record<string, unknown>> = [];
  private paymentTypes = Enum.TypePayment;
  private eventIsDone = Enum.eventStatus;
  private staff = [];
  private form: Record<string, any> = {
    type_of_currency: "IRR",
    price_of_currency: 1,
    mentors: [
      {
        mentorId: null,
        staffId: null
      }
    ]
  };
  private errors: Record<string, Array<string>> = {};
  private currencies = Enum.currencies;
  private datePopup = false;
  private loading = true;

  mounted() {
    if (this.$route.params.id) {
      this.getEvent(this.$route.params.id);
    } else this.loading = false;
    this.getStaff();
    this.getTimes();
  }

  public addTeam(): void {
    const mentors = this.form.mentors;
    mentors.push({
      mentorId: null,
      staffId: null
    });
    this.form.mentors = mentors;
  }

  public deleteTeam(index: number): void {
    if (this.form.mentors.length > 1) {
      const mentors = [...this.form.mentors];
      mentors.splice(index, 1);
      this.form.mentors = mentors;
    }
  }

  public async submit(): Promise<void> {
    try {
      const form = { ...this.form };
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      Object.keys(form).forEach((key) => {
        if (key == "mentors") {
          this.form[key].forEach((x: any, i: number) => {
            form["mentors[" + i + "][mentorId]"] = x.mentorId;
            form["mentors[" + i + "][staffId]"] = x.staffId;
          });
        }
      });
      delete form["mentors"];
      const res: AxiosResponse = await new EventApi().store(form);
      if (!this.$route.params.id) {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        this.form = {};
      }
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async getEvent(id: string): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().view(Number(id));
      const item = {
        ...res.data.data
      };
      item.type = item.type_original;
      this.form = item;
      this.loading = false;
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async getStaff(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().getStaff();
      this.staff = res.data.data;
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async getTimes(): Promise<void> {
    if (this.form.officeId && this.form.date) {
      const res: AxiosResponse = await new UserApi().getStaff();
      this.times = res.data.data;
    } else {
      const times = [];
      for (let i = 9; i <= 21; i++) {
        times.push({
          time: `ساعت ${("00" + i).substr(-2)}`,
          value: ("00" + i).substr(-2) + ":00",
          event: 0
        });
      }
      this.times = times;
    }
  }
}
